<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav text-white">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.email || '-' }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <v-gravatar :email="userData.email" :size="90" />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center text-primary"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span class="text-primary">Logout</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      style="font-size:10px;"
      disabled
    >
      <span>Version 0.2.49</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import Vue from 'vue'
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Gravatar from 'vue-gravatar'
import ability from '@/libs/acl/ability'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'

Vue.component('v-gravatar', Gravatar)

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: getUserData(),
      avatarText,
    }
  },
  methods: {
    logout() {
      window.$cookies.remove(useJwt.jwtConfig.storageTokenKeyName)
      window.$cookies.remove('userData')

      // Reset ability
      ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
