export default [
  {
    header: 'Gestor',
  },
  {
    title: 'Entregas',
    icon: 'SmileIcon',
    tagVariant: 'light-warning',
    route: 'check-driver',
  },
  {
    title: 'Pagamentos',
    icon: 'DollarSignIcon',
    tagVariant: 'light-warning',
    route: 'driver-payments',
  },
  {
    title: 'SpyLogin',
    icon: 'KeyIcon',
    tagVariant: 'light-warning',
    route: 'spy-login',
  },
]
