export default [
  {
    header: 'Administration',
  },
  {
    title: 'Stores',
    route: 'admin-stores',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Users',
    route: 'admin-users',
    icon: 'UserIcon',
  },
  {
    title: 'Drivers',
    route: 'admin-drivers',
    icon: 'UsersIcon',
  },
  {
    title: 'Merchants',
    route: 'admin-merchants',
    icon: 'UsersIcon',
  },
  {
    title: 'Pedidos',
    route: 'admin-orders',
    icon: 'ShoppingBagIcon',
  },
]
