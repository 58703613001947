export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'home',
  },
  {
    title: 'Pedidos entregues',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    route: 'driver-orders',
  },
  {
    title: 'Logout',
    icon: 'LogOutIcon',
    tagVariant: 'light-warning',
    route: 'auth-logout',
  },
]
