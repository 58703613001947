<template>
  <p class="clearfix mb-0" />
</template>

<script>
export default {
  components: {
  },
}
</script>
